import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { graphql } from "gatsby";
export const query = graphql`
query ($myComponentPath: String!) {
    mdx(fileAbsolutePath: {eq: $myComponentPath}) {
        frontmatter {
            image {
                publicURL
            }
            imageSmall {
                publicURL
            }
        }
    }
}
`;
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>
      <MDXTag name="p" components={components}>{`Maintainability of CSS project highly depends on developer's choices, on how a project was originally planned. Due to CSS cascading nature, it is easy to end up in a situation when you try to fix or add one thing, which breaks something else. In worst case scenario the issue will remain unnoticed and end up going to production. It is especially hard to work on poorly organized css in a large team (a case where large team has access to CSS files is `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://css-tricks.com/poll-wrapup-the-number-of-people-touching-css/"
        }}>{`not too common`}</MDXTag>{`, but is still valid).`}</MDXTag>
      <MDXTag name="h2" components={components}>{`BEM (or better GRM)`}</MDXTag>
      <MDXTag name="p" components={components}>{`Good news is that there are battle tested tools and approaches which help to keep things under control. One of them is the famous `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://en.bem.info/"
        }}>{`BEM`}</MDXTag>{`. Lets take a look at the following example:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-html"
        }}>{`<div class="shopping-cart-item">
  <div class="content special-offer">
    <h2 class="title">Title</h2>
  </div>
</div>
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`If at some point you'll need to remove whole `}<MDXTag name="inlineCode" components={components} parentName="p">{`.content`}</MDXTag>{` block from CSS which has nested `}<MDXTag name="inlineCode" components={components} parentName="p">{`.title`}</MDXTag>{`, you'll need to check if `}<MDXTag name="inlineCode" components={components} parentName="p">{`.special-offer`}</MDXTag>{` or even `}<MDXTag name="inlineCode" components={components} parentName="p">{`.shopping-cart-item`}</MDXTag>{` (and other ancestors) also have a nested `}<MDXTag name="inlineCode" components={components} parentName="p">{`.title`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Now lets take a look at one of the ways to write the same with BEM:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-html"
        }}>{`<div class="container container--shopping-cart">
  <div class="container__content container__content--special-offer">
    <h2 class="container__title">Title</h2>
  </div>
</div>
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`By looking at this, we immediately know the relationship of each of the elements and can safely do clean up, if we need to remove some styles. BEM is an abbreviation for Block, Element, Modifier and in the example above we can tell that `}<MDXTag name="inlineCode" components={components} parentName="p">{`container`}</MDXTag>{` is a Block, `}<MDXTag name="inlineCode" components={components} parentName="p">{`content`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`title`}</MDXTag>{` are Elements, `}<MDXTag name="inlineCode" components={components} parentName="p">{`shopping-cart`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`special-offer`}</MDXTag>{` are Modifiers. While approach is great, it sounds a bit weird to call `}<MDXTag name="inlineCode" components={components} parentName="p">{`container`}</MDXTag>{` a Block and `}<MDXTag name="inlineCode" components={components} parentName="p">{`content`}</MDXTag>{` an Element. When someone mentions BEM as an approach for selectors naming, I like to translate it to `}<MDXTag name="strong" components={components} parentName="p">{`GRM`}</MDXTag>{` - Group, Role, Modifier. Lets read it again: `}<MDXTag name="inlineCode" components={components} parentName="p">{`container`}</MDXTag>{` is a Group, `}<MDXTag name="inlineCode" components={components} parentName="p">{`content`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`title`}</MDXTag>{` define Roles within a Group, `}<MDXTag name="inlineCode" components={components} parentName="p">{`shopping-cart`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`special-offer`}</MDXTag>{` are Modifiers.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`To extend or not to extend?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Though there is something that is not nice in the example above. Having duplicated classes feels redundant. Lets see what we can do about it. For html example above styles typically look similar to following:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-css"
        }}>{`.container {
  border: 1px solid grey;
  padding: 10px;
  font-family: Arial, sans-serif;
}
  .container--shopping-cart {
    border: 4px solid orange;
    background-color: #eee;
  }
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`If one use preprocessors we could illuminate a need for duplicated classes using extends. Here is how you can use it with SCSS:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-scss"
        }}>{`.container {
  border: 1px solid grey;
  padding: 10px;
  font-family: Arial, sans-serif;
}
  .container--shopping-cart {
    @extend .container;
    border: 4px solid orange;
    background-color: #eee;
  }
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Here is a generated result:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-css"
        }}>{`.container, .container--shopping-cart {
  border: 1px solid grey;
  padding: 10px;
  font-family: Arial, sans-serif;
}
  .container--shopping-cart {
    border: 4px solid orange;
    background-color: #eee;
  }
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Looks nice, now we can use both `}<MDXTag name="inlineCode" components={components} parentName="p">{`.container`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`.container--shopping-cart`}</MDXTag>{` independently and CSS result still looks good. However `}<MDXTag name="inlineCode" components={components} parentName="p">{`@extend`}</MDXTag>{` should be used carefully, as it might significantly increase the size of your CSS file or `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "http://csswizardry.com/2014/11/when-to-use-extend-when-to-use-a-mixin/"
        }}>{`jumble specificity`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`So what can we do here to take advantage of behaviour which `}<MDXTag name="inlineCode" components={components} parentName="p">{`@extend`}</MDXTag>{` gives us, but without any risk? Here is a pure CSS solution:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-scss"
        }}>{`.container, [class^="container--"], [class*=" container--"] {
  border: 1px solid grey;
  padding: 10px;
  font-family: Arial, sans-serif;
}
  .container--shopping-cart {
    border: 4px solid orange;
    background-color: #eee;
  }
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{` It is going to work if your BEM modifiers start with `}<MDXTag name="inlineCode" components={components} parentName="p">{`%base%--`}</MDXTag>{`. Pretty much the only time when you want to extend something is when you need to take base styles and modify them in a way. `}<MDXTag name="inlineCode" components={components} parentName="p">{`[class^="container--"]`}</MDXTag>{` is taking care of a case when classname starts with selector, while `}<MDXTag name="inlineCode" components={components} parentName="p">{`[class*=" container--"]`}</MDXTag>{` takes care of cases when selector is in the middle or at the end of classname. I noticed that `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://icomoon.io/"
        }}>{`iconmoon`}</MDXTag>{` icon font generator is using that approach too. So now html could be simplified to following:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-html"
        }}>{`<div class="container--shopping-cart">
  <div class="container__content--special-offer">
    <h2 class="container__title">Title</h2>
  </div>
</div>
`}</MDXTag></MDXTag>
      <MDXTag name="h2" components={components}>{`Project organization tips`}</MDXTag>
      <MDXTag name="p" components={components}>{` `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/inuitcss/getting-started"
        }}>{`Inuit css`}</MDXTag>{` is a great example of how css project could be organized. You don't have to use styles from inuit if you don't want to. As long as you follow the same `}<MDXTag name="em" components={components} parentName="p">{`simple`}</MDXTag>{` rules as outlined in getting started guide, your components will be reusable, while project will be scalable and maintainable. Note how files in inuit are namespaced, you'll get similar benefits as described in an article `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "http://csswizardry.com/2015/03/more-transparent-ui-code-with-namespaces/"
        }}>{`More Transparent UI Code with Namespaces`}</MDXTag>{`, and you don't necessary have to prefix all your classnames with namespaces.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {
  "title": "CSS organization, naming conventions and safe extend without preprocessors",
  "date": "2015-03-14T14:37:31.000Z",
  "overview": "Opinion about how to organize clean and maintainable CSS structure, note about naming conventions, and how one can extend selectors without preprocessors",
  "image": "./image.jpg",
  "imageSmall": "./imageSmall.jpg",
  "type": "post"
};
    